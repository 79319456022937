// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  numero_WhatsApp: "+393288752130",
  numero_telefono: "tel:+393288752130",

  //text per personalizzare i messaggi
  canShowPagamentoRate: false,
  textShowPagamentoRate: "in comode <strong>2 rate</strong>",

  canShowElencoDate: true,

  /*
  urlWeb: 'http://localhost/',
  urlMaterialeDidattico: 'http://localhost/assets/media/materiale-didattico/1/',
  urlImageCorso: 'http://localhost/assets/media/corso/',
  urlImageCorsoHD: 'http://localhost/assets/media/corso/hd/',
  urlImageVideoCorso: 'http://localhost/assets/media/corso/video/',
  web_api_url_base: 'http://localhost/api/',

  urlStampa: 'http://localhost/stampe/',

  urlAttestati: 'http://localhost/assets/media/certificate/corsisti/',
  urlAttestatiDaCreare: 'http://localhost/pages/certificate/',
  urlImagePartners: 'http://localhost/assets/media/partners/',
  */

  urlWeb: 'https://csfsigma.discentya.it/',
  urlMaterialeDidattico: 'https://csfsigma.discentya.it/assets/media/materiale-didattico/1/',
  urlImageCorso: 'https://csfsigma.discentya.it/assets/media/corso/',
  urlImageCorsoHD: 'https://csfsigma.discentya.it/assets/media/corso/hd/',
  urlImageVideoCorso: 'https://csfsigma.discentya.it/assets/media/corso/video/',
  web_api_url_base: 'https://csfsigma.discentya.it/api/',

  urlStampa: 'https://csfsigma.discentya.it/stampe/',

  urlAttestati: 'https://csfsigma.discentya.it/assets/media/certificate/corsisti/',
  urlAttestatiDaCreare: 'https://csfsigma.discentya.it/pages/certificate/',
  urlImagePartners: 'https://csfsigma.discentya.it/assets/media/partners/',

};

